
import { Component, Prop } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';
import { mixins } from 'vue-class-component';
import FileUpload from '@/components/tasqsCommon/FileUpload.vue';
import { getComponent, sleep } from '@/utils/helpers';
import accountModule from '@/store/modules/accountModule';
import { getNameByEmail } from '@/utils/users';

@Component({
  components: {
    FileUpload,
    Checklist: () => getComponent('tasqsCommon/Checklist'),
    Multiselect,
  },
})
export default class WorkTicketSidebar extends mixins() {
  @Prop({ type: Object, required: true }) activeWorkTicket?: any;

  activeTab = 'Details';

  workTicketFiles: any = [];

  hideDateSection = false;

  workTicket: any = {};

  allowAutoSave = false;

  async created() {
    // console.log(this.activeWorkTicket);
    this.workTicket = { ...this.activeWorkTicket };

    this.prepareSidebarData();

    await sleep(1000);

    this.allowAutoSave = true;
  }

  prepareSidebarData() {
    this.workTicketSafetySteps = this.workTicket.details;

    this.workTicketFiles = this.workTicket.files;
    this.checklist = this.workTicket.detailedSteps;

    //   set createdBy

    this.ticketDetailsSection = this.ticketDetailsSection.map((step) => {
      const stepDetails = step;
      if (stepDetails.title === 'CreatedBy') {
        stepDetails.value = getNameByEmail(this.workTicket.createdBy);
      } else if (stepDetails.title === 'Assignee') {
        // @ts-ignore
        stepDetails.value = this.availableUsers.find((u) => u.value === this.workTicket.assignee);
        // console.log(this.workTicket);
        // @ts-ignore
        stepDetails.options = this.availableUsers;
      } else if (stepDetails.title === 'Priority') {
        stepDetails.value = this.workTicket.priority;
      } else if (stepDetails.title === 'Start Date') {
        stepDetails.value = this.workTicket.ticketStartDate;
      } else if (stepDetails.title === 'Due Date') {
        stepDetails.value = this.workTicket.ticketDueDate;
      }
      return stepDetails;
    });
  }

  get availableUsers() {
    return accountModule.reassignmentList
      .map((i) => ({
        // @ts-ignore
        text: i.name,
        value: i.email,
      }))
      .sort((a, b) => a.text.localeCompare(b.text));
  }

  checklist: any = [];

  workTicketSafetySteps = [
    {
      title: 'Is there a safety concern?',
      checked: true,
      hasDetailSection: true,
      name: 'safetyConcernRadioGroup',
      placeholder: 'Safety concern details here ...',
      options: [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
      ],
      details: 'Details for safety Concern go here',
    },

    {
      title: 'Is there an environmental concern?',
      checked: true,
      hasDetailSection: true,
      name: 'environmentalConcernRadioGroup',
      placeholder: 'Environmental concern details here ...',
      options: [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
      ],
      details: 'Environmental concern details here ...',
    },

    {
      title: 'Is the issue in the work ticket causing a production loss?',
      checked: false,
      hasDetailSection: false,
      dailyOilLossValue: '',
      dailyGasLossValue: '',
      hasDefermentSection: true,
      name: 'tiedToDefermentRadioGroup',
      placeholder: 'Environmental concern details here ...',
      options: [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
      ],
      details: '',
    },
  ];

  ticketDetailsSection = [
    {
      title: 'CreatedBy',
      type: 'text',
      value: 'Umar Ashraf',
    },
    {
      title: 'Assignee',
      placeholder: 'Select Assignee',
      options: [],
      type: 'dropdown',
      value: null,
    },
    {
      title: 'Priority',
      placeholder: 'Select Priority',
      type: 'dropdown',
      options: ['Highest', 'High', 'Medium', 'Low', 'Lowest'],
      value: null,
    },
    {
      title: 'Start Date',
      placeholder: 'Select Date',
      type: 'datepicker',
      options: ['Test'],
      value: null,
    },
    {
      title: 'Due Date',
      placeholder: 'Select Date',
      type: 'datepicker',
      options: ['Test'],
      value: null,
    },
  ];

  get startDate() {
    return this.ticketDetailsSection.find((item) => item.title === 'Start Date')?.value;
  }

  get dueDate() {
    return this.ticketDetailsSection.find((item) => item.title === 'Due Date')?.value;
  }

  get safetyConcernsDetails() {
    const safetyConcern = this.workTicketSafetySteps.find(
      (step) => step.name === 'safetyConcernRadioGroup',
    );
    return {
      title: 'Safety Concern',
      checkedValue: safetyConcern?.checked ? 'Yes' : 'No',
      details: safetyConcern?.details,
      checked: safetyConcern?.checked,
    };
  }

  get environmentalConcernsDetails() {
    const environmentalConcern = this.workTicketSafetySteps.find(
      (step) => step.name === 'environmentalConcernRadioGroup',
    );
    return {
      title: 'Environmental Concern',
      checkedValue: environmentalConcern?.checked ? 'Yes' : 'No',
      details: environmentalConcern?.details,
      checked: environmentalConcern?.checked,
    };
  }

  get defermentDetails() {
    const casuingDeferment = this.workTicketSafetySteps.find(
      (step) => step.name === 'tiedToDefermentRadioGroup',
    );
    return {
      title: 'Causing Deferment',
      checkedValue: casuingDeferment?.checked ? 'Yes' : 'No',
      details: '',
      checked: casuingDeferment?.checked,
      dailyGasLossValue: casuingDeferment?.dailyGasLossValue,
      dailyOilLossValue: casuingDeferment?.dailyOilLossValue,
    };
  }

  get safetySectionDetails(): any {
    return [this.safetyConcernsDetails, this.environmentalConcernsDetails, this.defermentDetails];
  }

  updateWorkTicketFiles(files) {
    this.workTicketFiles = files;
    if (!this.allowAutoSave) {
      return;
    }
    this.$emit('update-files', this.workTicketFiles);
  }

  updateChecklist(list) {
    this.checklist = list;
    if (!this.allowAutoSave) {
      return;
    }
    this.$emit('update-checklist', this.checklist);
  }

  updateDetailItem($el, item) {
    if (!this.allowAutoSave) {
      return;
    }

    this.hideDateSection = true;
    this.$nextTick(() => {
      this.hideDateSection = false;
    });
    if (item.title === 'Priority') {
      this.$emit('update-priority', item.value);
    } else if (item.title === 'Assignee') {
      this.$emit('update-assignee', item.value.value);
    } else if (item.title === 'Start Date') {
      this.$emit('update-startdate', item.value);
    } else if (item.title === 'Due Date') {
      this.$emit('update-enddate', item.value);
    }
  }
}
